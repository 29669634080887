<style scoped>
.pagebgimg{
    position: fixed;
    top: 0;
    z-index: -1;
    width: 100%;height: 100%;
  }
  .pagebgimg>img{
    width: 100%;height: 100%;
  }
  .inputstyll{
   background: rgba(255,255,255,0.5);
   border: initial;
  }
</style>
<template>
  <div class="book-edit">
    <header-title titleCon="角色创建"  @titleLeftBtn="titleFnReturn"></header-title>
    <div class="content book-edit-form form-block">
      <div class="form-item">
        <label>角色名称</label>
        <input class="inputstyll" type="text" v-model="roleInfo.roleName" placeholder="请填写角色名称" maxlength="15"/>
      </div>
      <div class="form-item">
        <label>形象昵称</label>
        <input class="inputstyll" type="text" v-model="roleInfo.roleNickname" placeholder="请填写角色昵称" maxlength="15"/>
      </div>
      <div class="form-item">
        <label>选择形象</label>
        <a v-if="roleInfo.roleImg.characterPath===''" @click="goChangeRole">
          <img src="../../assets/image/addroleadd.png" width="107"></a>
        <a v-else @click="goChangeRole"><img :src="roleInfo.roleImg.characterPath" width="107"/> </a>
      </div>
      <div class="form-item">
        <label>角色故事介绍</label>
        <textarea class="inputstyll" v-model="roleInfo.roleIntro" placeholder="请填写故事介绍" rows="5" maxlength="2000"/>
      </div>
      <div class="form-btn btn-bottom-box">
        <button class="btn-bottom" @click="titleFnListener">完成</button>
      </div>
    </div>
    <div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>
<script>
import headerTitle from '../../components/header/header-title-transparent.vue'
import qs from 'qs'
export default {
  components: {
    headerTitle
  },
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png'),
      isReClick: true,
      roleInfo: {
        roleName: '',
        roleNickname: '',
        roleIntro: '',
        roleImg: {
          addTime: '',
          characterPath: '',
          characterSubType: '',
          characterTitle: '',
          characterType: '',
          id: '',
          rackStatus: '',
          updTime: null
        }
      }
      // ugcCharacter: {
      //   id: '',
      //   ugcBookId: '',
      //   characterName: '',
      //   characterNickname: '',
      //   ugcCharacterpicId: '',
      //   ugcCharacterpicPath: '',
      //   characterIntro: null,
      //   joinStatus: null,
      //   addTime: '',
      //   updTime: null
      // }
    }
  },
  created () {
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
  },
  computed: {
  },
  mounted () {
    // 是否选择角色形象
    if (this.$route.query.roleType === 'edit') {
      this.axios({
        method: 'get',
        url: '/v1/character/toAddPage',
        params: {
          characterId: this.$route.query.roleId,
          type: this.$route.query.roleType
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          this.roleInfo = res.data.content.roleInfo
          if (this.$store.state.roleInfo.roleImg) {
            this.roleInfo = this.$store.state.roleInfo
          }
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    } else {
      if (this.$store.state.roleInfo.roleImg) {
        this.roleInfo = this.$store.state.roleInfo
      }
    }
  },
  methods: {
    titleFnReturn () {
      this.$router.push({ path: '/roleManage', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, chapterSet: this.$route.query.chapterSet, type: this.$route.query.type } })
    },
    // 选择形象
    goChangeRole () {
      this.$store.state.roleInfo = this.roleInfo
      this.$router.push({ path: '/roleList', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, chapterSet: this.$route.query.chapterSet, type: this.$route.query.type, roleId: this.$route.query.roleId, roleType: this.$route.query.roleType } })
    },
    // 添加形象
    titleFnListener () {
      if (this.roleInfo.roleName === '') {
        this.$toast('请输入角色名称')
      } else if (this.roleInfo.roleNickname.length > 15 || this.roleInfo.roleName.length > 15) {
        this.$toast('字数不能超过15字')
      } else if (this.roleInfo.roleImg.characterPath === '') {
        this.$toast('请添加角色形象')
      } else {
        var that = this
        if (this.isReClick) {
          this.axios({
            method: 'post',
            url: '/v1/character/save',
            data: qs.stringify({
              // authorId: sessionStorage.getItem('authorId'),
              ugcBookId: that.$route.query.bookId,
              chapterId: that.$route.query.chapterId,
              characterName: this.roleInfo.roleName,
              characterNickname: this.roleInfo.roleNickname,
              ugcCharacterpicId: this.roleInfo.roleImg.id,
              characterIntro: this.roleInfo.roleIntro,
              characterId: this.$route.query.roleId,
              type: this.$route.query.roleType
            })
          }).then((res) => {
            if (res.data.head.flag === 0) {
              this.$router.push({ path: '/roleManage', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, chapterSet: this.$route.query.chapterSet, type: this.$route.query.type, roleId: this.$route.query.roleId } })
              this.$store.state.roleInfo = {
                roleName: '',
                roleNickname: '',
                roleIntro: '',
                roleImg: ''
              }
              this.isReClick = false
            } else {
              this.$toast(res.data.head.desc)
            }
          })
        } else {
          this.$toast('重复点击了')
        }
      }
    }
  }
}
</script>
